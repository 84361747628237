// Width
.w {
  &-10-px {
    width: 10px;
  }

  &-18-px {
    width: 18px;
  }

  &-12-px {
    width: 12px;
  }

  &-20-px {
    width: 20px;
  }

  &-24-px {
    width: 24px;
  }

  &-36-px {
    width: 36px;
  }

  &-52-px {
    width: 52px;
  }

  &-56-px {
    width: 56px;
  }

  &-68-px {
    width: 68px;
  }

  &-80-px {
    width: 80px;
  }

  &-84-px {
    width: 84px;
  }

  &-96-px {
    width: 96px;
  }

  &-105-px {
    width: 105px;
  }

  &-128-px {
    width: 128px;
  }

  &-144-px {
    width: 144px;
  }

  &-148-px {
    width: 148px;
  }

  &-200-px {
    width: 200px;
  }

  // Responsive
  &-sm {}

  &-md {
    &-402-px {
      @include media-breakpoint-up(md) {
        width: 402px;
      }
    }
  }

  &-lg {}

  &-xl {}

  &-lg {
    &-auto {
      @include media-breakpoint-up(lg) {
        width: auto !important;
      }
    }
  }

  &-xxl {
    &-600-px {
      @include media-breakpoint-up(xxl) {
        width: 600px;
      }
    }
  }
}

// Min width
.mw {
  &-0-px {
    min-width: 0 !important;
  }

  &-12-px {
    min-width: 12px;
  }

  &-18-px {
    min-width: 18px;
  }

  &-20-px {
    min-width: 20px;
  }

  &-24-px {
    min-width: 24px;
  }

  &-48-px {
    min-width: 48px;
  }

  &-52-px {
    min-width: 52px;
  }

  &-80-px {
    min-width: 80px !important;
  }

  &-96-px {
    min-width: 96px;
  }

  &-100-px {
    min-width: 100px;
  }

  &-105-px {
    min-width: 105px;
  }

  &-116-px {
    min-width: 116px;
  }

  &-125-px {
    min-width: 125px;
  }

  &-128-px {
    min-width: 128px;
  }

  &-132-px {
    min-width: 132px;
  }

  &-135-px {
    min-width: 135px;
  }

  &-148-px {
    min-width: 148px;
  }

  &-160-px {
    min-width: 160px;
  }

  &-180-px {
    min-width: 180px;
  }

  &-182-px {
    min-width: 172px;
  }

  &-175-px {
    min-width: 175px !important;
  }

  &-220-px {
    min-width: 220px !important;
  }

  &-360-px {
    min-width: 360px;
  }

  &-848-px {
    min-width: 848px !important;
  }

  // Percentage
  &-100-p {
    min-width: 100% !important;
  }

  // Responsive
  &-sm {
    &-194-px {
      @include media-breakpoint-up(sm) {
        min-width: 194px;
      }
    }
  }

  &-md {
    &-280-px {
      @include media-breakpoint-up(md) {
        min-width: 280px !important;
      }
    }

    &-360-px {
      @include media-breakpoint-up(md) {
        min-width: 360px;
      }
    }

    &-360-px {
      @include media-breakpoint-up(md) {
        min-width: 360px;
      }
    }

    &-402-px {
      @include media-breakpoint-up(md) {
        min-width: 402px;
      }
    }
  }

  &-lg {}

  &-xl {}

  &-xxl {
    &-600-px {
      @include media-breakpoint-up(xxl) {
        min-width: 600px;
      }
    }
  }
}

// Max width
.mxw {
  &-56-px {
    max-width: 56px;
  }

  &-80-px {
    max-width: 80px;
  }

  &-100-px {
    max-width: 100px;
  }

  &-105-px {
    max-width: 105px;
  }

  &-125-px {
    max-width: 125px;
  }

  &-135-px {
    max-width: 135px;
  }

  &-160-px {
    max-width: 160px !important;
  }

  &-175-px {
    max-width: 175px;
  }

  &-180-px {
    max-width: 180px;
  }

  &-220-px {
    max-width: 220px;
  }

  &-264-px {
    max-width: 264px;
  }

  &-280-px {
    max-width: 280px;
  }

  &-309-px {
    max-width: 309px;
  }

  &-320-px {
    max-width: 320px;
  }

  &-328-px {
    max-width: 328px;
  }

  &-360-px {
    max-width: 360px;
  }

  &-432-px {
    max-width: 432px;
  }

  &-100-48-px {
    @include calc(max-width, '100% - 48px');
  }

  &-max-content {
    max-width: max-content;
  }

  // Responsive
  &-sm {
    &-80-px {
      @include media-breakpoint-up(sm) {
        max-width: 80px;
      }
    }

    &-328-px {
      @include media-breakpoint-up(sm) {
        max-width: 328px;
      }
    }
  }

  &-md {
    &-80-px {
      @include media-breakpoint-up(md) {
        max-width: 80px !important;
      }
    }

    &-280-px {
      @include media-breakpoint-up(md) {
        max-width: 280px;
      }
    }

    &-360-px {
      @include media-breakpoint-up(md) {
        max-width: 360px;
      }
    }

    &-440-px {
      @include media-breakpoint-up(md) {
        max-width: 440px;
      }
    }
  }

  &-lg {
    &-384-px {
      @include media-breakpoint-up(lg) {
        max-width: 384px;
      }
    }

    &-520-px {
      @include media-breakpoint-up(lg) {
        max-width: 520px;
      }
    }
  }

  &-xl {
    &-520-px {
      @include media-breakpoint-up(xl) {
        max-width: 520px;
      }
    }
  }

  &-xxl {
    &-800-px {
      @include media-breakpoint-up(xxl) {
        max-width: 800px;
      }
    }
  }
}

// Height
.h {
  &-10-px {
    height: 10px;
  }

  &-12-px {
    height: 12px;
  }

  &-18-px {
    height: 18px;
  }

  &-20-px {
    height: 20px;
  }

  &-24-px {
    height: 24px;
  }

  &-36-px {
    height: 36px;
  }

  &-48-px {
    height: 48px;
  }

  &-52-px {
    height: 52px;
  }

  &-80-px {
    height: 80px;
  }

  &-96-px {
    height: 96px;
  }

  &-120-px {
    height: 120px;
  }

  &-196-px {
    height: 196px;
  }

  &-100-60-px {
    @include calc(height, '100vh - 60px');
  }

  &-100-108-px {
    @include calc(height, '100vh - 108px');
  }

  &-100-156-px {
    @include calc(height, '100vh - 156px');
  }

  // Responsive
  &-sm {}

  &-md {}

  &-lg {}

  &-xl {}

  &-xxl {}
}

// Min height
.mh {
  &-48-px {
    min-height: 48px;
  }

  &-700-px {
    min-height: 700px;
  }

  // Responsive
  &-sm {}

  &-md {
    &-56-px {
      @include media-breakpoint-up(md) {
        min-height: 56px;
      }
    }
  }

  &-lg {}

  &-xl {}

  &-xxl {}
}

// Max height
.mxh {
  &-0-px {
    max-height: 0px;
  }

  &-28-px {
    max-height: 28px;
  }

  // Responsive
  &-sm {}

  &-md {}

  &-lg {}

  &-xl {}

  &-xxl {}
}

// Cols custom
.col {
  &-md {
    &-custom {
      &-20 {
        @include media-breakpoint-up(md) {
          flex: 0 0 20%;
          max-width: 20%;
        }
      }
    }
  }
}