$grid-columns:                12 !default;
$grid-gutter-width:           24px !default;

// @[Breakpoints]
// Screen Width
$bp-xs:  0;
$bp-sm:  576px;
$bp-md:  960px;
$bp-lg:  1248px;

// Container Width
$ct-xs:  100%;
$ct-sm:  100%;
// $ct-md:  912px + $grid-gutter-width;
$ct-md:  100%;
$ct-lg:  1224px + $grid-gutter-width;

// @[Grid Settings]
$grid-breakpoints: (
  xs:    $bp-xs,
  sm:    $bp-sm,
  md:    $bp-md,
  lg:    $bp-lg,
);

// @[Container Settings]
$container-max-widths: (
  xs:    $ct-xs,
  sm:    $ct-sm,
  md:    $ct-md,
  lg:    $ct-lg,
);