.app-dropdown {
  position: relative;

  &-toggle {}

  &-body {
    min-width: 280px;
    height: auto;
    max-height: 0;
    margin-top: 4px;
    overflow: auto;
    background-color: $white;
    border-radius: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    @include transition($transition-fast);
    //@extend .no-scroll-bar;
  }

  &-body-inner {
    padding: 12px 8px 8px;
  }

  &-item {
    &-button {
      color: $primary-text-color;
      @extend .d-flex;
      @extend .align-items-center;
      border-radius: 4px;
      padding: 6px 8px;
      @include transition($transition-fast);
      cursor: pointer;
      @extend .text-ellipsis;

      &:hover {
        background-color: $gray-200;
        color: $primary-text-color;
        text-decoration: none;
      }

      &:active {
        background-color: $gray-300;
      }

      &.active {
        color: $astral
      }
    }
  }

  // States
  &.open {
    .app-dropdown-body {
      max-height: 400px;
      @extend .shadow-5;
    }
  }

  // Sizes
  &-sm {
    .app-dropdown-body {
      min-width: 140px;
    }
  }

  &-lg {
    .app-dropdown-body {
      min-width: 435px;
    }

    .app-dropdown-body-inner {
      padding: 16px 16px 12px;
    }

    &.open {
      .app-dropdown-body {
        max-height: 550px;
      }
    }
  }

  // Direction
  &.opens {
    &-left {
      .app-dropdown-body {
        left: unset;
        right: 0;
      }
    }
    &-middle {
      .app-dropdown-body {
        left: 50%;
        @include translateX(-50%)
      }
    }
    &-up {
      .app-dropdown-body {
        top: unset;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: 4px;
      }
    }
    &-up-right,
    &-right-up {
      .app-dropdown-body {
        left: unset;
        right: 0;
        top: unset;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: 4px;
      }
    }
    &-middle-up,
    &-up-middle {
      .app-dropdown-body {
        top: unset;
        bottom: 100%;
        left: 50%;
        @include translateX(-50%);
        margin-bottom: 2px;
        margin-top: 0;
        margin-bottom: 4px;
      }
    }
  }
}

.content-item {
  cursor: pointer;
  padding: 10px 8px 6px 10px;
  border-radius: 4px;
  @include transition($transition-fast);

  * {
    @include transition($transition-fast);
  }

  svg path {
    fill: $gray-900
  }

  .text {
    color: $gray-800
  }

  &:hover {
    background-color: $gray-200;
    color: $primary-text-color;
    text-decoration: none;

    svg path {
      fill: $primary-text-color
    }
  }

  &:active {
    background-color: $gray-300;
  }

  &.active {
    &, .text {
      color: $astral;
    }

    svg path {
      fill: $astral;
    }
  }
}

.date-range-dropdown {
  position: relative;
  user-select: none;
  background-image: url('../../assets/images/icons/general/CarretDown.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: top 8px right 12px;

  .input {
    background-color: transparent;
  }

  input {
    padding-right: 24px;
  }

  .dropdown-list {
    min-width: 280px;
    max-height: 0;
    height: auto;
    background-color: $white;
    overflow: auto;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    border-radius: $radius * 2;
    margin-top: $spacer;
    @include transition($transition-fast);
    //@extend .no-scroll-bar;

    &__dense {
      transform: translate(-1px, 1px);
      width: calc(100% + 2px);
    }

    .dropdown-item {
      padding: 6px 8px;
      border-radius: $radius * 2;
      margin-bottom: $spacer;
      @extend .text-ellipsis;
      @extend .cursor-pointer;

      &:hover {
        background-color: $gray-200;
      }

      &:active {
        background-color: $gray-300;
      }

      &.active {
        background-color: $white;
        color: $astral;
      }
    }
  }

  &.open {
    .dropdown-list {
      max-height: 441px;
      @extend .shadow-5;
    }
  }
}
