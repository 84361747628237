// @[Spacers]
$spacer: 4px !default;
$spacers: (0:0);

@for $i from 1 through 100 {
  $spacers: map-merge($spacers, (
    $i: ($i * $spacer)
  ));
}
// Usage:
// p-1 ... p-100, mx-1 ... mx-100


// @[Size Settings]
$p: 0%;
$size: 5 !default;
$sizes: () !default;

@for $i from 1 through 100 {
  $sizes: map-merge($sizes, (
    $i * 5: ($i * $size) + $p
  ));
}
// Usage:
// w-5, w-10, w-15 ... w-100 (in percents)

// @[Theme Settings]
$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    // Main Colors
    "white":                  $white,
    "primary":                $primary,
    "secondary":              $secondary,
    "success":                $success,
    "info":                   $info,
    "warning":                $warning,
    "danger":                 $danger,
    "disabled":               $disabled,

    // Primary Colors
    "valencia":               $valencia,
    "cinnabar":               $cinnabar,
    "carnation":              $carnation,

    // Secondary Colors
    "casal":                  $casal,
    "paradiso":               $paradiso,
    "astral":                 $astral,

    // Tints
    "burnt-sienna":           $burnt-sienna,
    "tacao":                  $tacao,
    "apricot-peach":          $apricot-peach,
    "linen":                  $linen,
    "gull-gray":              $gull-gray,
    "submarine":              $submarine,
    "geyser":                 $geyser,
    "aqua-haze":              $aqua-haze,
    "cloud":                  $cloud,
    "westar":                 $westar,
    "pampas":                 $pampas,
    "spring-wood":            $spring-wood,

    // Alert Colors
    "celery":                 $celery,
    "deco":                   $deco,
    "chrome-white":           $chrome-white,
    "loafer":                 $loafer,
    "mandy":                  $mandy,
    "sea-pink":               $sea-pink,
    "pig-pink":               $pig-pink,
    "wisp-pink":              $wisp-pink,
    "fuel-yellow":            $fuel-yellow,
    "rajah":                  $rajah,
    "corvette":               $corvette,
    "half-colonial-white":    $half-colonial-white,
    "jordy-blue":             $jordy-blue,
    "blue":                   $blue,
    "tropical-blue":          $tropical-blue,
    "hawkes-blue":            $hawkes-blue,

    // Gray Factors
    "gray-100":               $gray-100,
    "gray-200":               $gray-200,
    "gray-300":               $gray-300,
    "gray-400":               $gray-400,
    "gray-500":               $gray-500,
    "gray-600":               $gray-600,
    "gray-700":               $gray-700,
    "gray-800":               $gray-800,
    "gray-900":               $gray-900,
  ),
  $theme-colors
);


