// ATTENTION!!! This is for custom classes/styles, try to avoid it and use global classes if possible
.cursor {
  &-default {
    cursor: default !important;
  }
  &-pointer {
    cursor: pointer !important;
  }
}

.no-select {
  @include no-select;
}

.no-scroll-bar {
  @include no-scroll-bar;
}

.no-list-style {
  list-style: none;
}

.no-number-appearance {
  @include hide-number-input-appearance
}

.no-date-appearance {
  @include hide-date-input-appearance
}

.no-time-appearance {
  @include hide-time-input-appearance
}

.list-marker {
  &-secondary {
    li::marker {
      color: $secondary;
    }
  }
}

.op {
  &-0 {
    opacity: 0;
  }
  &-1 {
    opacity: .1;
  }
  &-2 {
    opacity: .2;
  }
  &-3 {
    opacity: .3;
  }
  &-4 {
    opacity: .4;
  }
  &-5 {
    opacity: .5;
  }
  &-6 {
    opacity: .6;
  }
  &-7 {
    opacity: .7;
  }
  &-8 {
    opacity: .8;
  }
  &-9 {
    opacity: .9;
  }
}

.rotate {
  &-45 {
    @include rotate(45)
  }
  &-50 {
    @include rotate(50)
  }
  &-135 {
    @include rotate(135)
  }
  &-180 {
    @include rotate(180)
  }
  &-225 {
    @include rotate(225)
  }
  &-270 {
    @include rotate(270)
  }
  &-315 {
    @include rotate(315)
  }
}

.flip {
  &-x {
    @include rotateX(180)
  }
  &-y {
    @include rotateY(180)
  }
}

.overflow {
  &-hidden {
    overflow: hidden !important;
  }
  &-auto {
    overflow: auto !important;
  }
  &-scroll {
    overflow: scroll !important;
  }
  &-visible {
    overflow: visible !important;
  }

  // Responsive
  &-md {
    &-visible {
      @include media-breakpoint-up(md) {
        overflow: visible !important;
      }
    }
  }
}

.object {
  &-fit {
    &-cover {
      object-fit: cover;
    }
    &-contain {
      object-fit: contain;
    }
  }

  &-position {
    &-top {
      object-position: top;
    }
    &-center {
      object-position: center;
    }
    &-bottom {
      object-position: bottom;
    }
  }
}

.thumbnail {
  border-radius: 4px;
  border: 1px solid $gray-500;
  overflow: hidden;
  cursor: pointer;
  @include transition($transition-fast);

  &:hover {
    border-color: $carnation;
    box-shadow: 0 0 0 1px $tacao;
  }
}