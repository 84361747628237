.btn {
  height: $btn-h;
  font-size: $btn-fz;
  line-height: $btn-lh;
  padding: $btn-p;
  font-weight: $btn-fw;
  border-radius: $btn-radius;
  white-space: nowrap;
  @extend .d-inline-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  @include transition($transition-fast);

  svg.icon {
    width: 20px;
    height: 20px;

    path {
      @include transition($transition-fast);
    }
  }

  // Primary
  &-primary {
    &:not(:disabled):not(.disabled) {
      color: $btn-primary-color;
      background-color: $btn-primary-bg;
      border-color: $btn-primary-border;

      svg.icon path {
        fill: $btn-primary-color;
      }

      &:hover {
        background-color: $btn-primary-hover-bg;
        border-color: $btn-primary-hover-border;
      }

      &:active {
        background-color: $btn-primary-active-bg;
        border-color: $btn-primary-active-border;
      }

      &:focus, &:active, &:focus:active {
        box-shadow: $btn-primary-shadow;
      }
    }
  }

  // Primary Light
  &-primary-light {
    &:not(:disabled):not(.disabled) {
      color: $btn-primary-light-color;
      background-color: $btn-primary-light-bg;
      border-color: $btn-primary-light-border;

      svg.icon path {
        fill: $btn-primary-light-color;
      }

      &:hover {
        background-color: $btn-primary-light-hover-bg;
        border-color: $btn-primary-light-hover-border;
      }

      &:active {
        background-color: $btn-primary-light-active-bg;
        border-color: $btn-primary-light-active-border;
      }

      &:focus, &:active, &:focus:active {
        box-shadow: $btn-primary-light-shadow;
      }
    }
  }

  // Disabled state for Primary and Primary Light
  &-primary,
  &-primary-light {
    &:disabled, &.disabled {
      opacity: 1;
      color: $btn-primary-disabled-color;
      background-color: $btn-primary-disabled-bg;
      border-color: $btn-primary-disabled-border;

      svg.icon path {
        fill: $btn-primary-disabled-color;
      }
    }
  }

  // Secondary
  &-secondary {
    &:not(:disabled):not(.disabled) {
      color: $btn-secondary-color;
      background-color: $btn-secondary-bg;
      border-color: $btn-secondary-border;

      svg.icon path {
        fill: $btn-secondary-color;
      }

      &:hover {
        color: $btn-secondary-hover-color;
        background-color: $btn-secondary-hover-bg;
        border-color: $btn-secondary-hover-border;

        svg.icon path {
          fill: $btn-secondary-hover-color;
        }
      }

      &:active {
        color: $btn-secondary-active-color;
        background-color: $btn-secondary-active-bg;
        border-color: $btn-secondary-active-border;

        svg.icon path {
          fill: $btn-secondary-active-color;
        }
      }

      &:focus, &:active, &:focus:active {
        box-shadow: $btn-secondary-shadow;
      }
    }
  }

  // Secondary Light
  &-secondary-light {
    &:not(:disabled):not(.disabled) {
      color: $btn-secondary-light-color;
      background-color: $btn-secondary-light-bg;
      border-color: $btn-secondary-light-border;

      svg.icon path {
        fill: $btn-secondary-light-color;
      }

      &:hover {
        color: $btn-secondary-light-hover-color;
        background-color: $btn-secondary-light-hover-bg;
        border-color: $btn-secondary-light-hover-border;

        svg.icon path {
          fill: $btn-secondary-light-hover-color;
        }
      }

      &:active {
        color: $btn-secondary-light-active-color;
        background-color: $btn-secondary-light-active-bg;
        border-color: $btn-secondary-light-active-border;

        svg.icon path {
          fill: $btn-secondary-light-active-color;
        }
      }

      &:focus, &:active, &:focus:active {
        box-shadow: $btn-secondary-light-shadow;
      }
    }
  }

  // Disabled state for Secondary and Secondary Light
  &-secondary,
  &-secondary-light {
    &:disabled, &.disabled {
      opacity: 1;
      color: $btn-secondary-disabled-color;
      background-color: $btn-secondary-disabled-bg;
      border-color: $btn-secondary-disabled-border;

      svg.icon path {
        fill: $btn-secondary-disabled-color;
      }
    }
  }

  // Text
  &-text {
    &:not(:disabled):not(.disabled) {
      color: $btn-text-color;
      background-color: $btn-text-bg;
      border-color: $btn-text-border;

      svg.icon path {
        fill: $btn-text-color;
      }

      &:hover {
        color: $btn-text-hover-color;
        background-color: $btn-text-hover-bg;
        border-color: $btn-text-hover-border;

        svg.icon path {
          fill: $btn-text-hover-color;
        }
      }

      &:active {
        color: $btn-text-active-color;
        background-color: $btn-text-active-bg;
        border-color: $btn-text-active-border;

        svg.icon path {
          fill: $btn-text-active-color;
        }
      }

      &:focus, &:active, &:focus:active {
        box-shadow: $btn-text-shadow;
      }
    }
  }

  // Text Light
  &-text-light {
    &:not(:disabled):not(.disabled) {
      color: $btn-text-light-color;
      background-color: $btn-text-light-bg;
      border-color: $btn-text-light-border;

      svg.icon path {
        fill: $btn-text-light-color;
      }

      &:hover {
        color: $btn-text-light-hover-color;
        background-color: $btn-text-light-hover-bg;
        border-color: $btn-text-light-hover-border;

        svg.icon path {
          fill: $btn-text-light-hover-color;
        }
      }

      &:active {
        color: $btn-text-light-active-color;
        background-color: $btn-text-light-active-bg;
        border-color: $btn-text-light-active-border;

        svg.icon path {
          fill: $btn-text-light-active-color;
        }
      }

      &:focus, &:active, &:focus:active {
        box-shadow: $btn-text-light-shadow;
      }
    }
  }

  // Disabled state for Text and Text Light
  &-text,
  &-text-light {
    &:disabled, &.disabled {
      opacity: 1;
      color: $btn-text-disabled-color;
      background-color: $btn-text-disabled-bg;
      border-color: $btn-text-disabled-border;

      svg.icon path {
        fill: $btn-text-disabled-color;
      }
    }
  }

  &-social {
    &:not(:disabled):not(.disabled) {
      color: $btn-social-color;
      background-color: $btn-social-bg;
      border-color: $btn-social-border;

      &:hover {
        color: $btn-social-hover-color;
        background-color: $btn-social-hover-bg;
        border-color: $btn-social-hover-border;
      }

      &:active {
        color: $btn-social-active-color;
        background-color: $btn-social-active-bg;
        border-color: $btn-social-active-border;
      }

      &:focus, &:active, &:focus:active {
        box-shadow: $btn-social-shadow;
      }
    }
  }

  // Behaviors
  &-icon {
    &:not(.btn-only-icon) {
      &:not(.btn-reverse) {
        padding-left: 11px;

        svg.icon {
          margin-right: 6px;
        }
      }

      &.btn-reverse {
        padding-right: 11px;

        svg.icon {
          margin-left: 6px;
        }
      }
    }

    &:not(.btn-sm).btn-only-icon {
      min-width: $btn-h;
      padding-left: 7px;
      padding-right: 7px;
    }
  }

  // Sizes
  &-sm {
    min-width: $btn-sm-w;
    height: $btn-sm-h;
    font-size: $btn-sm-fz;
    line-height: $btn-sm-lh;
    padding: $btn-sm-p;

    svg.icon {
      width: 16px;
      height: 16px;
    }

    &.btn-icon {
      &.btn-only-icon {
        min-width: $btn-sm-h;
        padding-left: 3px;
        padding-right: 3px;
      }
    }
  }
}

.pagination {
  margin-bottom: 0;

  li {
    a {
      @extend .btn;

      svg {
        width: 20px;
        height: 20px;

        path {
          @include transition($transition-fast);
        }
      }
    }

    &:not(.active) {
      a {
        @extend .btn-text;
      }
    }

    &.active:not(.disabled):not(:disabled) {
      a {
        @extend .btn-secondary-light;
      }
    }

    &.previous,
    &.next {
      &:hover svg path {
        fill: $btn-text-hover-color;
      }

      &:active svg path {
        fill: $btn-text-active-color;
      }
    }

    &.previous {
      margin-right: $spacer * 2;

      a::after {
        content: 'Prev'
      }

      svg {
        margin-right: 6px;
      }

      & ~ li:not(.next) {
        margin-right: $spacer;
      }
    }

    &.next {
      margin-left: $spacer;

      a::before {
        content: 'Next'
      }

      svg {
        margin-left: 6px;
      }
    }
  }
}


.btn-group {
  .btn {
    &:not(:disabled):not(.disabled) {
      &,
      &:focus,
      &:active,
      &:focus:active,
      &.active {
        box-shadow: none;
      }
    }

    &-primary-light {
      &:not(:disabled):not(.disabled) {
        &,
        &:focus,
        &:active,
        &:focus:active,
        &.active {
          border-color: $btn-secondary-border;
        }

        &.btn-column {
          svg {
            rect, path {
              stroke: $carnation;
            }
          }
        }
      }
    }
  }
}