.integration {
  &-container {
    margin-top: $spacer * 6;
    @extend .col-6
  }

  &-card {
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: $radius * 2;
    position: relative;
    @include transition($transition-fast);

    &:hover {
      border-color: $burnt-sienna;
      z-index: 1;
    }

    &:active {
      background-color: $gray-100;
    }
  }

  &-content {
    @extend .d-flex;
    @extend .align-items-start;
  }

  &-row {
    margin-top: -1px;
    @extend .col-12;

    .integration-content {
      @extend .align-items-center;
    }

    .integration-text {
      display: none;
    }

    .integration-card {
      border-radius: 0;
    }

    &:first-child .integration-card {
      border-top-left-radius: $radius * 2;
      border-top-right-radius: $radius * 2;
    }

    &:last-child .integration-card {
      border-bottom-left-radius: $radius * 2;
      border-bottom-right-radius: $radius * 2;
    }
  }
}

.popup-card {
  .overlay {
    background-color: transparent;
    @include transition($transition-fast);

    &:hover {
      background-color: transparentize($gray-900, .7);
    }
  }
}

.template-card {
  border: 1px solid $border-color;
  @include transition($transition-fast);

  .overlay {
    opacity: 0;
    background-color: transparentize($white, .05);
    @include transition($transition-fast);
  }

  &:hover {
    border-color: $burnt-sienna;

    .overlay {
      opacity: 1;
    }
  }
}

.step-card {
  position: relative;
  margin-top: -1px;

  &:not(:last-child) {
    &::after {
      content: '';
      width: 2px;
      background-color: $gray-600;
      position: absolute;
      top: 50px;
      bottom: -29px;
      left: 35px;
      z-index: 1;
    }

    &.completed,
    &.skipped {
      &::after {
        background-color: $gray-900;
      }
    }
  }
}

.notification-card {
  display: block;
  padding: 6px 8px;
  margin-bottom: 4px;
  border-left: 4px solid $carnation;
  @extend .text-undecorated;
  @include transition($transition-fast);

  &-read {
    border-left-color: transparent;
  }

  &:not(.notification-card-lg) {
    @extend .pull-x-2;
  }

  .title {
    color: $primary-text-color;
    margin-bottom: 2px;
  }

  .date {
    margin-bottom: 0;
    @extend .text-xs;
    @extend .text-secondary;
  }

  &:hover {
    background-color: $gray-200
  }

  &:active {
    background-color: $gray-300
  }

  &-lg {
    position: relative;
    padding: 12px 8px;
    margin-bottom: 0;

    &:not(.notification-card-read) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        @extend .border-bottom;
      }
    }
  }
}

.plan-card {
  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  @include media-breakpoint-up(sm) {

    &:nth-child(odd) {
      border-right: 1px solid $border-color;
    }

    &:nth-child(3) {
      border-bottom: none;
    }
  }

  @include media-breakpoint-up(md) {
    min-height: 618px;

    &, &:not(:last-child) {
      border-bottom: none;
    }

    &:not(:last-child) {
      border-right: 1px solid $border-color;
    }
  }
}

.aside-toolbar {
  &::before,
  &::after {
    content: '';
    display: block;
    width: 1px;
    background-color: $border-color;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}

.positioning {
  &-card {
    @extend .d-flex;
    @extend .flex-wrap;
    border: 1px solid $cloud;
    border-radius: 4px;
    overflow: hidden;
    background-color: $spring-wood;
  }

  &-item {
    @extend .col-4;
    height: 46px;
    cursor: pointer;
    background-color: transparent;
    @include transition($transition-fast);
    border: none;
    // border-right: 1px solid $westar;
    // border-bottom: 1px solid $westar;


    &:nth-child(3n + 1) {
      border-right: 1px solid $westar;
    }

    &:nth-child(3n + 3) {
      border-left: 1px solid $westar;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      border-bottom: 1px solid $westar;
    }

    &:not(.active):hover {
      background-color: $white;
    }

    &.active {
      background-color: $cloud;
    }
  }

  &-card-sm {
    .positioning-item {
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(7),
      &:nth-child(9) {
        @extend .col-6;
        border-left: none;
      }


      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(8) {
        display: none;
      }
    }
  }
}

// .#C1BEBB {}