// @[Color Palette]
// Default Colors
$white:                             #FFFFFF;

// Primary Colors
$valencia:                          #D24A3F;
$cinnabar:                          #E55346;
$carnation:                         #F45D48;

// Secondary Colors
$casal:                             #2A585D;
$paradiso:                          #347A87;
$astral:                            #398E9F;

// Tints
$burnt-sienna:                      #FFD1BA;
$tacao:                             #FADCCD;
$apricot-peach:                     #FCEAE1;
$linen:                             #FEF6F3;
$gull-gray:                         #96B1B3;
$submarine:                         #B4C8C9;
$geyser:                            #D1DEDF;
$aqua-haze:                         #EBF2F3;
$cloud:                             #C1BEBB;
$westar:                            #E4E1DE;
$pampas:                            #F1EEEB;
$spring-wood:                       #F8F5F2;

// Alert Colors
$celery:                            #9AC85C;
$deco:                              #C3DEA0;
$chrome-white:                      #DBEBC5;
$loafer:                            #F1F7E8;
$mandy:                             #E95656;
$sea-pink:                          #EA9B9D;
$pig-pink:                          #FCCDD3;
$wisp-pink:                         #FDEBEE;
$fuel-yellow:                       #F0A531;
$rajah:                             #F5BF6D;
$corvette:                          #FAD8A7;
$half-colonial-white:               #FDF0DB;
$jordy-blue:                        #7FB3F2;
$blue:                              #9FC8F7;
$tropical-blue:                     #C3DDFA;
$hawkes-blue:                       #E6F2FD;

// Gray Shades
$gray-100:                          #FBFBFC; // Light Gray
$gray-200:                          #F6F6F9; // Soft Gray
$gray-300:                          #ECECEF; // Athens Gray
$gray-400:                          #D2D5D9; // Iron
$gray-500:                          #DBE2EB; // Mystic
$gray-600:                          #B3BAC3; // Cadet Blue
$gray-700:                          #8C8E90; // Oslo Gray
$gray-800:                          #66696D; // Nevada
$gray-900:                          #131313; // Cod Gray
