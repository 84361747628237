.filter-search-navigation {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(19, 19, 19, 0.3);
  opacity: 0;
  @include transition($transition-fast);
  z-index: -1;

  @include media-breakpoint-up(md) {
    position: static;
    background-color: transparent;
    opacity: 1;
  }

  .content {
    padding: 16px 24px 32px;
    border-radius: 12px 0px 0px 12px;
    background-color: $white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    @include transition($transition-fast);
    @include translate(100%, 0);

    @include media-breakpoint-up(md) {
      position: static;
      padding: 0;
      border-radius: 0;
      @include translate(0, 0);
    }
  }

  &.open {
    opacity: 1;
    z-index: 2;

    .content {
      @include translate(0, 0);
    }
  }
}