.auth-slider {
  @include calc(width, '100vw - 440px');

  @include media-breakpoint-up(lg) {
    @include calc(width, '100vw - 520px');
  }

  @include media-breakpoint-up(xxl) {
    @include calc(width, '100vw - 800px');
  }

  .slick-arrow {
    z-index: 1;
    width: 32px;
    top: 400px;
    @include translate(0, 0);

    &:before {
      content: '';
      display: none;
    }

    &.slick-prev {
      left: 40px
    }

    &.slick-next {
      right: 40px;
    }
  }

  .slick-dots {
    top: 700px;
    bottom: unset;

    li {
      width: 8px;
      height: 8px;
      display: none;
      margin: 0;

      &:not(:last-child) {
        margin-right: 4px;
      }

      button {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        padding: 0;
        background-color: $burnt-sienna;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        width: 36px;

        button {
          background-color: $primary;
        }

        &,
        &~li {
          display: inline-block;
        }

        &:nth-child(2) {
          width: 48px;
        }

        &:nth-child(3) {
          width: 60px;
        }
      }
    }
  }
}