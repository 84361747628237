.input-container {
  margin-bottom: 16px;
}

.input {
  border: 1px solid $input-border-color;
  border-radius: $input-radius;
  background-color: $input-bg;
  color: $input-color;
  padding: 7px 11px;
  cursor: text;
  @extend .d-flex;
  @extend .align-items-center;
  @include transition($transition-fast);

  &:not(.input-textarea) {
    height: $input-h;
  }

  svg {
    min-width: 20px;
  }

  input,
  textarea {
    height: 100%;
    border: none;
    outline: none;
    font-size: $input-fz;
    line-height: $input-lh;
    font-weight: $input-fw;
    background-color: transparent;
    @extend .flex-fill;

    @include placeholder {
      color: $input-placeholder-color;
    }

    &:first-child:last-child {
      max-width: 100%;
    }

    &:first-child:not(:last-child),
    &:last-child:not(:first-child) {
      @include calc(max-width, '100% - 20px - 8px'); // -8px for icon margin
    }
  }

  textarea {
    resize: none;
    min-height: 80px;
  }

  & ~ .input-feedback {
    padding: 0 5px;
    color: $gray-800;
    font-size: $text-xs-fz;
    line-height: $text-xs-lh;
    font-weight: 400;
    @extend .d-flex;
    @extend .text-pre-line;
  }

  // Pseudo States
  &:hover {
    border-color: $input-hover-border;
  }

  &:focus-within {
    border-color: $input-hover-border;
    //border-color: $input-focus-border;
    box-shadow: $input-focus-shadow;
  }

  // States
  &.is-valid {
    border-color: $success;

    &:focus-within {
      border-color: $input-valid-focus-border;
      box-shadow: $input-valid-focus-shadow;
    }
  }

  &.is-invalid {
    border-color: $danger;

    &:focus-within {
      border-color: $input-invalid-focus-border;
      box-shadow: $input-invalid-focus-shadow;
    }

    & ~ .input-feedback {
      color: $danger
    }
  }

  &:disabled, &.disabled {
    color: $input-disabled-color;
    border-color: $input-disabled-border;

    svg path {
      fill: $input-disabled-color;
    }

    input,
    textarea {
      @include placeholder {
        color: $input-disabled-placeholder-color;
      }
    }
  }
}

.input-container-select {
  position: relative;

  select {
    text-indent: 0;
    background-image: url('../../assets/images/icons/general/CarretDown.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: right 11px top 6px;
    @include no-appearance;

    &:not(.disabled):not(:disabled) {
      cursor: pointer
    }
  }
}

.checkbox{
  width: 24px;
  height: 24px;
  position: relative;
  @include transition($transition-normal);
  cursor: pointer;
  margin-bottom: 0;

  .area {
    width: 18px;
    height: 18px;
    margin: 3px;
    display: block;
    border-radius: 3px;
    border: 1px solid $checkbox-border;
    background-position: center;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    @include transition($transition-normal);
  }

  input {
    opacity: 0;

    &:checked {
      ~ .area {
        background-color: $checkbox-checked-bg;
        border-color: $checkbox-checked-border;
        background-image: url('../../assets/images/icons/general/CheckmarkWhite.svg');
      }
    }
  }

  &:not(.disabled):not(.checked) {
    &:hover .area {
      background-color: $checkbox-hover-bg;
      border-color: $checkbox-hover-border;
    }

    &:focus-within .area {
      box-shadow: $checkbox-shadow;
    }

    &:active .area {
      background-color: $checkbox-active-bg;
      border-color: $checkbox-active-border;
    }
  }

  &:not(.disabled).checked {
    &:hover .area {
      background-color: $checkbox-checked-hover-bg;
      border-color: $checkbox-checked-hover-border;
    }

    &:focus-within .area {
      box-shadow: $checkbox-checked-shadow;
    }

    &:active .area {
      background-color: $checkbox-checked-active-bg;
      border-color: $checkbox-checked-active-border;
    }
  }

  // States
  &.disabled {
    cursor: default;

    &:not(.checked) {
      .area {
        background-color: $checkbox-disabled-bg;
        border-color: $checkbox-disabled-border;
      }
    }

    &.checked {
      .area {
        background-color: $checkbox-checked-disabled-bg;
        border-color: $checkbox-checked-disabled-border;
      }
    }
  }

  &.indeterminated {
    &, &:not(.checked) {
      .area {
        background-image: url('../../assets/images/icons/general/MinusWhite.svg') !important;
      }

      &:not(.disabled) {
        .area {
          background-color: $checkbox-checked-bg;
          border-color: $checkbox-checked-border;
        }

        &:hover .area {
          background-color: $checkbox-checked-hover-bg;
          border-color: $checkbox-checked-hover-border;
        }

        &:focus-within .area {
          box-shadow: $checkbox-checked-shadow;
        }

        &:active .area {
          background-color: $checkbox-checked-active-bg;
          border-color: $checkbox-checked-active-border;
        }
      }
    }

    &.disabled {
      .area {
        background-color: $checkbox-checked-disabled-bg;
        border-color: $checkbox-checked-disabled-border;
      }
    }
  }
}

.radio{
  width: 24px;
  height: 24px;
  position: relative;
  @include transition($transition-normal);
  cursor: pointer;
  margin-bottom: 0;

  .area {
    width: 20px;
    height: 20px;
    margin: 2px;
    display: block;
    border-radius: 50%;
    border: 1px solid $checkbox-border;
    position: absolute;
    top: 0;
    left: 0;
    @include transition($transition-normal);
  }

  input {
    opacity: 0;

    &:checked {
      ~ .area {
        background-color: $checkbox-checked-bg;
        border-color: $checkbox-checked-border;
        box-shadow: inset 0 0 0 2px $white;
      }
    }
  }

  &:not(.disabled):not(.checked) {
    &:hover .area {
      background-color: $checkbox-hover-bg;
      border-color: $checkbox-hover-border;
    }

    &:focus-within .area {
      box-shadow: $checkbox-shadow;
    }

    &:active .area {
      background-color: $checkbox-active-bg;
      border-color: $checkbox-active-border;
    }
  }

  &:not(.disabled).checked {
    &:hover .area {
      background-color: $checkbox-checked-hover-bg;
      border-color: $checkbox-checked-hover-border;
    }

    &:focus-within .area {
      box-shadow: inset 0 0 0 2px $white, $checkbox-checked-shadow;
    }

    &:active .area {
      background-color: $checkbox-checked-active-bg;
      border-color: $checkbox-checked-active-border;
    }
  }

  &.disabled {
    cursor: default;
  }

  &.disabled:not(.checked) {
    .area {
      background-color: $checkbox-disabled-bg;
      border-color: $checkbox-disabled-border;
    }
  }

  &.disabled.checked {
    .area {
      background-color: $checkbox-checked-disabled-bg;
      border-color: $checkbox-checked-disabled-border;
    }
  }
}

.range-input {
  &-content {
    width: $range-input-width;
    height: $range-input-height;
    position: relative;
  }

  &-progress {
    cursor: pointer;
    background: $aqua-haze;
    position: absolute;
    width: 100%;
    height: $range-input-height;
    border-radius: $range-input-radius;

    span {
      border-radius: $range-input-radius;
      background: $astral;
      height: $range-input-height;
      display: block;
    }
  }

  input {
    width: 100%;
    height: $range-input-height;
    border-radius: $range-input-radius;
    background: transparent;
    outline: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    @include no-appearance;

    &::-moz-range-progress {
      background: $astral;
      height: $range-input-height;
      border-radius: $range-input-radius;
    }

    &::-ms-fill-upper {
      background: $astral;
      height: $range-input-height;
      border-radius: $range-input-radius;
    }

    &::-webkit-slider-thumb {
      width: $thumb-size;
      height: $thumb-size;
      background: $white;
      border: 2px solid $astral;
      border-radius: $thumb-radius;
      cursor: pointer;
      @include no-appearance;
      @include transition($transition-fast);
      opacity: 1;
    }

    &::-moz-range-thumb {
      width: $thumb-size;
      height: $thumb-size;
      background: $white;
      border: 2px solid $astral;
      border: 1px solid $gray-300;
      border-radius: $thumb-radius;
      cursor: pointer;
      @include transition($transition-fast);
      opacity: 1;
    }
  }
}

.checkbox-label {
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 8px;
  margin-bottom: $spacer;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .no-select;
  @include transition($transition-fast);

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $gray-200;
    color: $primary-text-color;
    text-decoration: none;
  }

  &:active {
    background-color: $gray-300;
  }

  &.active {
    color: $astral
  }
}

.color-input {
  &-container {
    margin-bottom: $spacer * 4;
  }

  &-toggle {
    @extend .d-inline-flex;
    @extend .align-items-center;
    @extend .rounded-2;
    padding: 7px 12px;
    cursor: pointer;
    border: 1px solid $input-border-color;
    @include transition($transition-fast);

    &:hover {
      border-color: $input-hover-border;
    }
  }

  &-content {
    width: 232px !important;
    padding: 16px 16px 6px !important;

    // Color area
    >div:nth-child(1) {
      padding-bottom: 144px !important;
      border-radius: 4px !important;
      margin-bottom: 16px !important;
    }

    // Alpha area
    .flexbox-fix:nth-child(2) {
      margin-bottom: 16px !important;

      >div:nth-child(1) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        >div {
          border-radius: 5px !important;

          &:first-child {
            margin-bottom: 16px !important;
          }

          &:last-child {
            margin-top: 0 !important;
          }
        }
      }

      >div:nth-child(2) {
        display: none !important;
      }
    }

    // Color parameters
    .flexbox-fix:nth-child(3) {
      padding-top: 0 !important;
      margin-bottom: 16px !important;

      // RGBA
      >div:not(:first-child) {
        display: none;
      }

      // HEX
      >div {
        >div {
          &::after {
            content: 'HEX';
            display: inline-block;
            margin-left: 12px;
          }
        }

        input {
          width: 90px !important;
          height: 36px !important;
          border-radius: 4px !important;
          border-color: $input-border-color !important;
          font-size: $input-fz !important;
          font-weight: $input-fw !important;
          line-height: $input-lh !important;
        }

        label {
          display: none !important;
        }
      }
    }

    // Favorite colors
    .flexbox-fix:nth-child(4) {
      div {
        width: 24px !important;
        height: 24px !important;
        border-radius: 20px !important;

        span {
          div {
            box-shadow: none !important;

            &:hover {
              @include scale(1.1);
            }
          }
        }
      }
    }
  }
}
