h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  font-family: $montserrat;
  font-weight: $h-fw;
  margin-bottom: $h-mb;
}

h1, .h1 {
  font-size: $h1-fz;
  line-height: $h1-lh;
}

h2, .h2 {
  font-size: $h2-fz;
  line-height: $h2-lh;
}

h3, .h3 {
  font-size: $h3-fz;
  line-height: $h3-lh;
}

h4, .h4 {
  font-size: $h4-fz;
  line-height: $h4-lh;
}

p {
  margin-bottom: $p-mb;
}

.fw {
  &-100 {
    font-weight: 100
  }
  &-200 {
    font-weight: 200
  }
  &-300 {
    font-weight: 300
  }
  &-400, &-regular {
    font-weight: 400
  }
  &-500, &-medium {
    font-weight: 500
  }
  &-600, &-semibold {
    font-weight: 600
  }
  &-700, &-bold {
    font-weight: 700
  }
  &-800 {
    font-weight: 800
  }
  &-900 {
    font-weight: 900
  }
}

.text {
  // Sizes
  &-lg {
    font-size: $text-lg-fz;
    line-height: $text-lg-lh
  }
  &-md {
    font-size: $text-md-fz;
    line-height: $text-md-lh
  }
  &-sm {
    font-size: $text-sm-fz;
    line-height: $text-sm-lh
  }
  &-xs {
    font-size: $text-xs-fz;
    line-height: $text-xs-lh
  }

  // Alignment
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
  &-justify {
    text-align: justify;
  }

  // Behavior
  &-ellipsis {
    @include ellipsis();

    &-2 {
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &-clip {
    @include clip();
  }
  &-pre {
    white-space: pre;

    &-line {
      white-space: pre-line;
    }
  }
  &-undecorated {
    &, &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }
  &-underline {
    &, &:hover, &:focus, &:active {
      text-decoration: underline;
    }
  }
  &-line-through {
    text-decoration: line-through;
  }

  //Responsive
  &-sm {}
  &-md {}
  &-lg {}
  &-xl {}
  &-xxl {}
}
