.hyperlink {
  font-weight: $hyperlink-fw;
  text-decoration: underline;
  position: relative;
  z-index: 1;
  @include transition($transition-fast);

  &::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 4px;
    z-index: -1;
    @include transition($transition-fast);
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):not(.hyperlink-white) {
    color: $hyperlink-color;

    &:hover {
      color: $hyperlink-hover-color;
    }

    &:focus {
      &::after {
        box-shadow: $hyperlink-shadow;
      }
    }

    &:active {
      color: $hyperlink-active-color;
    }

    &.hyperlink-light {
      color: $hyperlink-light-color;

      &:hover {
        color: $hyperlink-hover-color;
      }

      &:not(:active):focus {
        color: $hyperlink-light-focus-color;
      }

      &:active {
        color: $hyperlink-active-color;
      }

    }
  }

  &-white {
    &, &:hover, &:focus, &:active {
      color: $white;
    }

    &:focus {
      &::after {
        box-shadow: $hyperlink-shadow;
      }
    }
  }

  &:disabled, &.disabled {
    color: $hyperlink-disabled-color;
    cursor: default;
    pointer-events: none;
  }
}