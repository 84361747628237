$shadow-level-1:                      0px 1px 1px rgba(140, 142, 144, 0.14),
                                      0px 2px 1px rgba(140, 142, 144, 0.12),
                                      0px 1px 3px rgba(140, 142, 144, 0.2);
$shadow-level-2:                      0px 2px 2px rgba(140, 142, 144, 0.14),
                                      0px 3px 1px rgba(140, 142, 144, 0.12),
                                      0px 1px 5px rgba(140, 142, 144, 0.2);
$shadow-level-3:                      0px 3px 4px rgba(140, 142, 144, 0.14),
                                      0px 3px 3px rgba(140, 142, 144, 0.12),
                                      0px 1px 8px rgba(140, 142, 144, 0.2);
$shadow-level-4:                      0px 4px 5px rgba(140, 142, 144, 0.14),
                                      0px 1px 10px rgba(140, 142, 144, 0.12),
                                      0px 2px 4px rgba(140, 142, 144, 0.2);
$shadow-level-5:                      0px 6px 10px rgba(140, 142, 144, 0.14),
                                      0px 1px 18px rgba(140, 142, 144, 0.12),
                                      0px 3px 5px rgba(140, 142, 144, 0.2);