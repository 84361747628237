.progress {
  height: 8px;
  border-radius: 8px;
  background-color: $aqua-haze;

  .progress-bar {
    background-color: $astral;
  }

  &-sm {
    height: 4px;
    border-radius: 4px;
  }
}