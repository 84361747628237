.snackbar {
  border-radius: 30px !important;
  padding: $snack-p !important;
  background-color: $gray-800 !important;
  color: $primary-text-color !important;
  font-size: $snack-fz !important;
  line-height: $snack-fz !important;
  box-shadow: $shadow-level-1 !important;

  @include media-breakpoint-up(sm) {
    min-width: 232px !important;
  }

  div:first-child {
    padding: 0;
  }

  div:last-child {
    margin-right: 0;
  }

  // States
  &-success {
    background-color: $snack-success-bg !important;
  }

  &-info {
    background-color: $snack-info-bg !important;
  }

  &-warning {
    background-color: $snack-warning-bg !important;
  }

  &-danger {
    background-color: $snack-danger-bg !important;
  }
}