.switch {
  width: 40px;
  height: 24px;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  margin: 0 0 10px;
  padding: 0;
  position: relative;
  display: inline-block;

  .label {
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  input {
    opacity: 0;

    &:checked {
      ~ .area {
        background-color: $switch-checked-bg;
      }

      ~ .circle {
        @include translate(16px, 0);
      }
    }
  }

  .area {
    width: 100%;
    height: 100%;
    background-color: $switch-color;
    border-radius: 50px;
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    margin: 0;
    position: absolute;
    top: 0;
    @include transition($transition-fast);
  }

  .circle {
    background-color: $white;
    border-radius: 18px;
    display: block;
    height: 20px;
    margin: 2px;
    padding: 0;
    position: absolute;
    top: 0;
    @include transition($transition-normal);
    width: 20px;
  }

  // Pseudo States
  &:not(.disabled) .label:not(.checked) {
    &:hover .area {
      background-color: $switch-hover-color;
    }

    &:focus-within .area {
      box-shadow: $switch-shadow;
    }

    &:active .area {
      background-color: $switch-active-color;
    }
  }

  &:not(.disabled) .label.checked {
    &:hover .area {
      background-color: $switch-checked-hover-color;
    }

    &:focus-within .area {
      box-shadow: $switch-checked-shadow;
    }

    &:active .area {
      background-color: $switch-checked-active-color;
    }
  }

  // States
  &.disabled {
    .label {
      cursor: default;
      pointer-events: none;
    }
    .area {
      background-color: $switch-disabled-color !important;
    }
  }
}