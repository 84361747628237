.toggle {
  border: 1px solid $toggle-border-color;
  border-radius: $toggle-border-radius;
  padding: $toggle-padding;
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: auto;
    max-width: max-content;
  }

  button {
    width: 50%;

    &:not(:last-child) {
      @include calc(width, '50% - 1px')
    }

    @include media-breakpoint-up(sm) {
      &, &:not(:last-child) {
        width: auto;
      }
    }


    &:not(:disabled):not(.disabled) {
      height: 36px;
      padding-top: 7px;
      padding-bottom: 7px;
      border-radius: 3px;

      &.selected {
        @extend .btn-primary;

        &:focus, &:active, &:focus:active {
          box-shadow: none;
        }
      }

      &:not(.selected) {
        color: $primary;
        background-color: transparent;
        border-color: transparent;

        &:hover {
          background-color: $btn-primary-light-bg;
          border-color: $btn-primary-light-border;
        }

        &:active {
          background-color: $btn-primary-light-active-bg;
          border-color: $btn-primary-light-active-border;
        }

        &:focus, &:active, &:focus:active {
          box-shadow: none;
        }
      }

      &:not(:last-child) {
        margin-right: 1px;
      }
    }
  }
}