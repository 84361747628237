.alert {
  margin-bottom: 0;
  padding: $alert-p;
  font-size: $alert-fz;
  line-height: $alert-lh;
  border-radius: $alert-radius;
  border: none;

  // Primary
  &-primary {}

  // Secondary
  &-secondary {}

  // Info
  &-info {
    color: $alert-info-color;
    background-color: $alert-info-bg;
  }

  // Warning
  &-warning {}

  // Success
  &-success {}

  // Danger
  &-danger {
    color: $alert-danger-color;
    background-color: $alert-danger-bg;
  }

  // Sizes
  &-sm {
    padding: $alert-sm-p;
  }
}