.ReactModal__Overlay {
  opacity: 0;
  @include transition($transition-fast);
  background-color: transparentize($gray-700, .3) !important;
  z-index: 10;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  padding: 0 10px;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {
  width: 100%;
  max-width: $modal-md-width;
  background-color: $white;
  border-radius: $radius * 6;
  position: relative;

  &:focus-visible {
    outline: none;
  }

  .modal-close {
    position: absolute;
    top: 24px;
    right: 24px;

    svg path {
      fill: $gray-500
    }

    &:hover svg path {
      fill: $gray-700
    }
  }

  .modal-header {
    padding: $spacer * 6 $spacer * 6 $spacer * 4;
    border-bottom: none;
  }

  .modal-body {
    padding: 0 $spacer * 6;
    position: static;
    overflow: auto;
  }

  .modal-footer {
    border: none;
    padding: $spacer * 6;
    @extend .justify-content-end;

    > * {
      margin: 0;
    }

    button:not(:first-child) {
      margin-left: $spacer * 2;
    }
  }

  // Sizes
  &-sm {
    max-width: $modal-sm-width;
  }
  &-lg {
    max-width: $modal-lg-width;
  }
  &-xl {
    @include calc(max-width, $modal-xl-width);
    @include calc(max-height, $modal-xl-height);

    @include media-breakpoint-up(lg) {
      @include calc(max-width, $modal-xl-width-lg);
    }

    .modal-body {
      @include calc(max-height, $modal-xl-body-height);
    }
  }
}
