// @[Typography]
// Titles
$h1-fz:                              28px;
$h1-lh:                              36px;

$h2-fz:                              24px;
$h2-lh:                              36px;

$h3-fz:                              20px;
$h3-lh:                              28px;

$h4-fz:                              18px;
$h4-lh:                              26px;

$h-fw:                               700;
$h-mb:                               16px;

// Texts
$text-lg-fz:                         16px;
$text-lg-lh:                         24px;

$text-md-fz:                         14px;
$text-md-lh:                         20px;

$text-sm-fz:                         12px;
$text-sm-lh:                         16px;

$text-xs-fz:                         11px;
$text-xs-lh:                         14px;

$p-mb:                               16px;
