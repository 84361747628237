.pull {
  // All
  &-1 {
    margin: -$spacer;
  }
  &-2 {
    margin: -$spacer * 2
  }
  &-3 {
    margin: -$spacer * 3
  }
  &-4 {
    margin: -$spacer * 4
  }
  &-5 {
    margin: -$spacer * 5
  }
  &-6 {
    margin: -$spacer * 6
  }
  &-7 {
    margin: -$spacer * 7
  }
  &-8 {
    margin: -$spacer * 8
  }
  &-9 {
    margin: -$spacer * 9;
  }
  &-10 {
    margin: -$spacer * 10;
  }
  &-11 {
    margin: -$spacer * 11;
  }
  &-12 {
    margin: -$spacer * 12;
  }
  &-13 {
    margin: -$spacer * 13;
  }
  &-14 {
    margin: -$spacer * 14;
  }
  &-15 {
    margin: -$spacer * 15;
  }
  &-16 {
    margin: -$spacer * 16;
  }

  // Left
  &-l {
    &-1 {
      margin-left: -$spacer
    }
    &-2 {
      margin-left: -$spacer * 2
    }
    &-3 {
      margin-left: -$spacer * 3
    }
    &-4 {
      margin-left: -$spacer * 4
    }
    &-5 {
      margin-left: -$spacer * 5
    }
    &-6 {
      margin-left: -$spacer * 6
    }
    &-7 {
      margin-left: -$spacer * 7
    }
    &-8 {
      margin-left: -$spacer * 8
    }
    &-9 {
      margin-left: -$spacer * 9;
    }
    &-10 {
      margin-left: -$spacer * 10;
    }
    &-11 {
      margin-left: -$spacer * 11;
    }
    &-12 {
      margin-left: -$spacer * 12;
    }
    &-13 {
      margin-left: -$spacer * 13;
    }
    &-14 {
      margin-left: -$spacer * 14;
    }
    &-15 {
      margin-left: -$spacer * 15;
    }
    &-16 {
      margin-left: -$spacer * 16;
    }
    &-35 {
      margin-left: -$spacer * 35;
    }
  }

  // Right
  &-r {
    &-1 {
      margin-right: -$spacer
    }
    &-2 {
      margin-right: -$spacer * 2
    }
    &-3 {
      margin-right: -$spacer * 3
    }
    &-4 {
      margin-right: -$spacer * 4
    }
    &-5 {
      margin-right: -$spacer * 5
    }
    &-6 {
      margin-right: -$spacer * 6
    }
    &-7 {
      margin-right: -$spacer * 7
    }
    &-8 {
      margin-right: -$spacer * 8
    }
    &-9 {
      margin-right: -$spacer * 9;
    }
    &-10 {
      margin-right: -$spacer * 10;
    }
    &-11 {
      margin-right: -$spacer * 11;
    }
    &-12 {
      margin-right: -$spacer * 12;
    }
    &-13 {
      margin-right: -$spacer * 13;
    }
    &-14 {
      margin-right: -$spacer * 14;
    }
    &-15 {
      margin-right: -$spacer * 15;
    }
    &-16 {
      margin-right: -$spacer * 16;
    }
  }

  // Top
  &-t {
    &-1 {
      margin-top: -$spacer;
      &-px {
        margin-top: -1px;
      }
    }
    &-2 {
      margin-top: -$spacer * 2;
    }
    &-3 {
      margin-top: -$spacer * 3;
    }
    &-4 {
      margin-top: -$spacer * 4;
    }
    &-5 {
      margin-top: -$spacer * 5;
    }
    &-6 {
      margin-top: -$spacer * 6;
    }
    &-7 {
      margin-top: -$spacer * 7;
    }
    &-8 {
      margin-top: -$spacer * 8;
    }
    &-9 {
      margin-top: -$spacer * 9;
    }
    &-10 {
      margin-top: -$spacer * 10;
    }
    &-11 {
      margin-top: -$spacer * 11;
    }
    &-12 {
      margin-top: -$spacer * 12;
    }
    &-13 {
      margin-top: -$spacer * 13;
    }
    &-14 {
      margin-top: -$spacer * 14;
    }
    &-15 {
      margin-top: -$spacer * 15;
    }
    &-16 {
      margin-top: -$spacer * 16;
    }
    &-17 {
      margin-top: -$spacer * 17;
    }
    &-18 {
      margin-top: -$spacer * 18;
    }
    &-19 {
      margin-top: -$spacer * 19;
    }
    &-20 {
      margin-top: -$spacer * 20;
    }
    &-36 {
      margin-top: -$spacer * 36;
    }
  }

  // Bottom
  &-b {
    &-1 {
      margin-bottom: -$spacer;
      &-px {
        margin-bottom: -1px;
      }
    }
    &-2 {
      margin-bottom: -$spacer * 2;
    }
    &-3 {
      margin-bottom: -$spacer * 3;
    }
    &-4 {
      margin-bottom: -$spacer * 4;
    }
    &-5 {
      margin-bottom: -$spacer * 5;
    }
    &-6 {
      margin-bottom: -$spacer * 6;
    }
    &-7 {
      margin-bottom: -$spacer * 7;
    }
    &-8 {
      margin-bottom: -$spacer * 8;
    }
    &-9 {
      margin-bottom: -$spacer * 9;
    }
    &-10 {
      margin-bottom: -$spacer * 10;
    }
    &-11 {
      margin-bottom: -$spacer * 11;
    }
    &-12 {
      margin-bottom: -$spacer * 12;
    }
    &-13 {
      margin-bottom: -$spacer * 13;
    }
    &-14 {
      margin-bottom: -$spacer * 14;
    }
    &-15 {
      margin-bottom: -$spacer * 15;
    }
    &-16 {
      margin-bottom: -$spacer * 16;
    }
  }

  // Horizontal
  &-x {
    &-1 {
      margin-left: -$spacer;
      margin-right: -$spacer;
    }
    &-2 {
      margin-left: -$spacer * 2;
      margin-right: -$spacer * 2;
    }
    &-3 {
      margin-left: -$spacer * 3;
      margin-right: -$spacer * 3;
    }
    &-4 {
      margin-left: -$spacer * 4;
      margin-right: -$spacer * 4;
    }
    &-5 {
      margin-left: -$spacer * 5;
      margin-right: -$spacer * 5;
    }
    &-6 {
      margin-left: -$spacer * 6;
      margin-right: -$spacer * 6;
    }
    &-7 {
      margin-left: -$spacer * 7;
      margin-right: -$spacer * 7;
    }
    &-8 {
      margin-left: -$spacer * 8;
      margin-right: -$spacer * 8;
    }
    &-9 {
      margin-left: -$spacer * 9;
      margin-right: -$spacer * 9;
    }
    &-10 {
      margin-left: -$spacer * 10;
      margin-right: -$spacer * 10;
    }
    &-11 {
      margin-left: -$spacer * 11;
      margin-right: -$spacer * 11;
    }
    &-12 {
      margin-left: -$spacer * 12;
      margin-right: -$spacer * 12;
    }
    &-13 {
      margin-left: -$spacer * 13;
      margin-right: -$spacer * 13;
    }
    &-14 {
      margin-left: -$spacer * 14;
      margin-right: -$spacer * 14;
    }
    &-15 {
      margin-left: -$spacer * 15;
      margin-right: -$spacer * 15;
    }
    &-16 {
      margin-left: -$spacer * 16;
      margin-right: -$spacer * 16;
    }
  }

  // Vertical
  &-y {
    &-1 {
      margin-top: -$spacer;
      margin-bottom: -$spacer;
    }
    &-2 {
      margin-top: -$spacer * 2;
      margin-bottom: -$spacer * 2;
    }
    &-3 {
      margin-top: -$spacer * 3;
      margin-bottom: -$spacer * 3;
    }
    &-4 {
      margin-top: -$spacer * 4;
      margin-bottom: -$spacer * 4;
    }
    &-5 {
      margin-top: -$spacer * 5;
      margin-bottom: -$spacer * 5;
    }
    &-6 {
      margin-top: -$spacer * 6;
      margin-bottom: -$spacer * 6;
    }
    &-7 {
      margin-top: -$spacer * 7;
      margin-bottom: -$spacer * 7;
    }
    &-8 {
      margin-top: -$spacer * 8;
      margin-bottom: -$spacer * 8;
    }
    &-9 {
      margin-top: -$spacer * 9;
      margin-bottom: -$spacer * 9;
    }
    &-10 {
      margin-top: -$spacer * 10;
      margin-bottom: -$spacer * 10;
    }
    &-11 {
      margin-top: -$spacer * 11;
      margin-bottom: -$spacer * 11;
    }
    &-12 {
      margin-top: -$spacer * 12;
      margin-bottom: -$spacer * 12;
    }
    &-13 {
      margin-top: -$spacer * 13;
      margin-bottom: -$spacer * 13;
    }
    &-14 {
      margin-top: -$spacer * 14;
      margin-bottom: -$spacer * 14;
    }
    &-15 {
      margin-top: -$spacer * 15;
      margin-bottom: -$spacer * 15;
    }
    &-16 {
      margin-top: -$spacer * 16;
      margin-bottom: -$spacer * 16;
    }
  }
}

.p {
  &y {
    &-6-px {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
}

// Aspect-ratio
.aspect {
  &-16-9 {
    padding-top: 56.25%;
  }
  &-4-3 {
    padding-top: 75%;
  }
}