* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html,
body {
  font-family: $primary-font;
  font-size: $primary-font-size;
  line-height: $primary-line-height;
  font-weight: $primary-font-weight;
  color: $primary-text-color;
  background-color: $primary-bg-color;
  @include font-smoothing(antialiased);

  &.overflow-touch-hidden {
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      overflow: visible;
    }
  }
}

img {
  max-width: 100%;
}

hr {
  border-top-color: $border-color;
}

.container {
  @include media-breakpoint-up(md) {
    padding-left: $spacer * 6;
    padding-right: $spacer * 6;
  }
}

.bg-white--prime {
  &:not(:hover):not(:active),
  &.disabled:hover,
  &:disabled:hover {
    background-color: $white !important;
  }
}

.bg-spring-wood--prime {
  &:not(:hover):not(:active),
  &.disabled:hover,
  &:disabled:hover {
    background-color: $spring-wood !important;
  }
}