@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin no-select {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

@mixin font-face ($name: null, $path: null, $format: null, $weight: 300, $style: normal) {
  @font-face {
    font-family: $name;
    src: url('#{$fonts-folder-path}#{$path}') format($format);
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin no-appearance {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;

  // For IE use
  &::-ms-expand {
    display: none;
  }
}

@mixin no-scroll-bar {
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    height: 0;
    width: 0;
  }
}

@mixin font-smoothing($value: antialiased) {
  @if $value==antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin hide-number-input-appearance {
  input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    &,
    &:hover,
    &:focus {
      -moz-appearance: textfield;
    }
  }
}

@mixin hide-date-input-appearance {
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}

@mixin hide-time-input-appearance {
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }
}