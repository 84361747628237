
@import "utils/main";

@import "~bootstrap/scss/bootstrap.scss";
@import "~react-dates/lib/css/_datepicker.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
// All third party packages should be imported here...

@import "reboot";

@import "packages/main";

@import "base/main";

@import "components/main";

@import "layouts/main";

@import "pages/main";
