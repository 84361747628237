.password-strength-check {
  div:nth-child(1),
  div:nth-child(2) {
    display: none;
  }

  div:nth-child(odd) {
    height: 4px !important;
    border-radius: 4px;
  }

  div:nth-child(even) {
    width: 12px !important;
  }
}