.fill {
  &-white path {
    fill: $white
  }
  &-success path {
    fill: $success
  }
  &-info path {
    fill: $info
  }
  &-warning path {
    fill: $warning
  }
  &-danger path {
    fill: $danger
  }
  &-primary path {
    fill: $primary
  }
  &-secondary path {
    fill: $secondary
  }
  &-astral path {
    fill: $astral
  }
  &-gray-100 path {
    fill: $gray-100
  }
  &-gray-200 path {
    fill: $gray-200
  }
  &-gray-300 path {
    fill: $gray-300
  }
  &-gray-400 path {
    fill: $gray-400
  }
  &-gray-500 path {
    fill: $gray-500
  }
  &-gray-600 path {
    fill: $gray-600
  }
  &-gray-700 path {
    fill: $gray-700
  }
  &-gray-800 path {
    fill: $gray-800
  }
  &-gray-900 path {
    fill: $gray-900
  }
}

.stroke {
  &-white path {
    stroke: $white
  }
  &-success path {
    stroke: $success
  }
  &-info path {
    stroke: $info
  }
  &-warning path {
    stroke: $warning
  }
  &-danger path {
    stroke: $danger
  }
  &-primary path {
    stroke: $primary
  }
  &-secondary path {
    stroke: $secondary
  }
  &-astral path {
    stroke: $astral
  }
  &-gray-100 path {
    stroke: $gray-100
  }
  &-gray-200 path {
    stroke: $gray-200
  }
  &-gray-300 path {
    stroke: $gray-300
  }
  &-gray-400 path {
    stroke: $gray-400
  }
  &-gray-500 path {
    stroke: $gray-500
  }
  &-gray-600 path {
    stroke: $gray-600
  }
  &-gray-700 path {
    stroke: $gray-700
  }
  &-gray-800 path {
    stroke: $gray-800
  }
  &-gray-900 path {
    stroke: $gray-900
  }
}
