.drop-zone {
  border: 1px dashed $gray-600;
  padding: $spacer * 6;
  border-radius: $radius * 2;
  text-align: center;
  background-color: transparent;
  @include transition($transition-fast);

  &-active {
    border-color: $carnation;
    background-color: $linen;
  }
}