.shadow {
  &-1 {
    box-shadow: $shadow-level-1;
  }
  &-2 {
    box-shadow: $shadow-level-2;
  }
  &-3 {
    box-shadow: $shadow-level-3;
  }
  &-4 {
    box-shadow: $shadow-level-4;
  }
  &-5 {
    box-shadow: $shadow-level-5;
  }
}
