.border {
  &-transparent {
    &:not(.border-success):not(.border-warning):not(.border-info):not(.border-danger) {
      border-color: transparent !important
    }
  }

  &-dashed {
    border: 1px dashed;
  }

  &-top-dashed {
    border-top: 1px dashed;
  }

  &-default {
    border-color: $border-color;
  }

  &-1 {
    border-width: $spacer !important;

    &-px {
      border-width: 1px !important;
    }
  }
  &-2 {
    border-width: $spacer * 2 !important;

    &-px {
      border-width: 2px !important;
    }
  }
  &-3 {
    border-width: $spacer * 3 !important;

    &-px {
      border-width: 3px !important;
    }
  }
  &-4 {
    border-width: $spacer * 4 !important;

    &-px {
      border-width: 4px !important;
    }
  }
}

.rounded {
  &-50p {
    border-radius: 50%;
  }

  &-1 {
    border-radius: $radius
  }
  &-2 {
    border-radius: $radius * 2;
  }
  &-3 {
    border-radius: $radius * 3;
  }
  &-4 {
    border-radius: $radius * 4
  }
  &-5 {
    border-radius: $radius * 5
  }
  &-6 {
    border-radius: $radius * 6
  }
  &-7 {
    border-radius: $radius * 7
  }
  &-8 {
    border-radius: $radius * 8;
  }
  &-9 {
    border-radius: $radius * 9;
  }
  &-10 {
    border-radius: $radius * 10;
  }

  &-left-1 {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius
  }
  &-left-2 {
    border-top-left-radius: $radius * 2;
    border-bottom-left-radius: $radius * 2
  }
  &-left-3 {
    border-top-left-radius: $radius * 3;
    border-bottom-left-radius: $radius * 3
  }
  &-left-4 {
    border-top-left-radius: $radius * 4;
    border-bottom-left-radius: $radius * 4
  }

  &-right-1 {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius
  }
  &-right-2 {
    border-top-right-radius: $radius * 2;
    border-bottom-right-radius: $radius * 2
  }
  &-right-3 {
    border-top-right-radius: $radius * 3;
    border-bottom-right-radius: $radius * 3
  }
  &-right-4 {
    border-top-right-radius: $radius * 4;
    border-bottom-right-radius: $radius * 4
  }

  &-top-1 {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius
  }
  &-top-2 {
    border-top-left-radius: $radius * 2;
    border-top-right-radius: $radius * 2
  }
  &-top-3 {
    border-top-left-radius: $radius * 3;
    border-top-right-radius: $radius * 3
  }
  &-top-4 {
    border-top-left-radius: $radius * 4;
    border-top-right-radius: $radius * 4
  }

  &-bottom-1 {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius
  }
  &-bottom-2 {
    border-bottom-left-radius: $radius * 2;
    border-bottom-right-radius: $radius * 2
  }
  &-bottom-3 {
    border-bottom-left-radius: $radius * 3;
    border-bottom-right-radius: $radius * 3
  }
  &-bottom-4 {
    border-bottom-left-radius: $radius * 4;
    border-bottom-right-radius: $radius * 4
  }
}
