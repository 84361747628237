.tabs {
  // Tab Type Horizontal
  &-horizontal {
    border-bottom: none;

    .nav-link {
      font-size: $tab-fz;
      line-height: $tab-lh;
      font-weight: $tab-fw;
      text-align: center;
      padding: $tab-md-p;
      color: $tab-color;
      background-color: $tab-bg;
      border: none;
      border-bottom: 3px solid $tab-border;
      border-radius: 0;
      margin-bottom: 0;
      @include transition($transition-fast);

      &:not(.active) {
        &:hover {
          color: $tab-hover-color;
          background-color: $tab-hover-bg;
          border-color: $tab-hover-border;
        }

        &:active {
          color: $tab-active-color;
          background-color: $tab-active-bg;
          border-color: $tab-active-border;
        }
      }

      &.active {
        color: $tab-selected-color;
        background-color: $tab-selected-bg;
        border-bottom-color: $tab-selected-border;
      }
    }

    // Types
    &.bordered {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid $border-color;
      }
    }

    // Sizes
    &-sm {
      .nav-link {
        padding: $tab-sm-p;
      }
    }
    &-lg {
      .nav-link {
        padding: $tab-lg-p;
      }
    }
  }

  // Tab Type Vertical
  &-vertical {
    .nav-item {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    .nav-link {
      font-size: $tab-fz;
      line-height: $tab-lh;
      font-weight: $tab-fw;
      padding: $tab-ver-p;
      border-radius: 4px;
      @include transition($transition-fast);

      &:not(.disabled) {
        color: $tab-color;
        background-color: $tab-bg;
      }

      &:not(.active) {
        &:hover {
          color: $tab-hover-color;
          background-color: $tab-hover-bg;
        }

        &:active {
          color: $tab-active-color;
          background-color: $tab-active-bg;
        }
      }

      &.active {
        color: $tab-selected-color;
        background-color: $tab-selected-vertical-bg;
        border-bottom-color: $tab-selected-border;
      }
    }
  }
}

