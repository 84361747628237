.header {
  height: $header-height;
  background-color: $header-bg;
  position: relative;
  z-index: 2;

  &.shadowed {
    @extend .shadow-3
  }

  &.bordered {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid $border-color;
    }
  }

  .nav {
    height: 100%;

    ul {
      list-style: none;
      padding: 0;
    }

    > ul {
      @extend .d-flex;
      @extend .h-100;
      margin-bottom: 0;

      > li {
        position: relative;
        @extend .d-flex;
        @extend .align-items-center;
        @extend .cursor-pointer;

        &:not(:last-child) {
          margin-right: 24px;
        }

        .nav-parent {
          height: 100%;
          color: $primary-text-color;
          text-decoration: none;
          font-weight: 600;
          @extend .d-flex;
          @extend .align-items-center;
          @include transition($transition-fast);

          & ~ svg path {
            fill: $primary-text-color;
            @include transition($transition-fast);
          }

          &:hover {
            color: $primary;

            & ~ svg path {
              fill: $primary
            }
          }
        }
      }

      ul {
        width: 280px;
        height: auto;
        max-height: 0;
        background-color: $white;
        padding: 0 8px;
        border-radius: 4px;
        overflow: auto;
        @extend .no-scroll-bar;
        position: absolute;
        top: 100%;
        left: 50%;
        z-index: 1;
        @include translate(-50%, 2px);
        @include transition($transition-fast);

        .nav-child {
          display: block;
          padding: 6px 8px;
          color: $primary-text-color;
          text-decoration: none;
          @include transition($transition-fast);

          &:hover {
            color: $primary;
          }
        }
      }

      >li:hover ul {
        max-height: 400px;
        padding: 12px 8px;
        @extend .shadow-5;
      }
    }
  }
}