.chips {
  height: $chips-h;
  color: $chips-color;
  background-color: $chips-bg;
  border: 1px solid transparent;
  padding: 1px 7px 1px 11px;
  border-radius: 20px;
  @extend .text-xs;
  @extend .no-select;
  @extend .d-inline-flex;
  @extend .align-items-center;
  @include transition($transition-fast);

  &:not(.disabled) {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    &:not(.dragged):not(.selected) {
      &:hover {
        background-color: $chips-hover-bg;
      }

      &:active {
        background-color: $chips-active-bg;
      }

      &:not(:active):focus {
        border-color: $chips-focus-border;
      }
    }

    &.dragged {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
      @extend .shadow-2;
    }

    &.selected {
      padding-right: 11px;
      color: $chips-selected-color;
      background-color: $chips-selected-bg;
    }
  }

  &.disabled {
    color: $disabled;
    background-color: $chips-disabled-bg;
  }
}