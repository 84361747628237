// Browser Prefixes
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

// Rotate
@mixin rotate ($deg) {
	@include transform(rotate(#{$deg}deg));
}
@mixin rotateX ($deg) {
	@include transform(rotateX(#{$deg}deg));
}
@mixin rotateY ($deg) {
	@include transform(rotateY(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

@mixin translateX ($x) {
	@include transform(translateX($x));
}

@mixin translateY ($y) {
	@include transform(translateY($y));
}

// Skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	transform-origin: $origin;
}
