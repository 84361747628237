.footer {
  height: $footer-height;
  background-color: $footer-bg;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid $border-color;
  }

  .nav {
    height: 100%;

    ul {
      list-style: none;
      padding: 0;
    }

    > ul {
      @extend .d-flex;
      @extend .h-100;
      margin-bottom: 0;

      > li {
        position: relative;
        @extend .d-flex;
        @extend .align-items-center;
        @extend .cursor-pointer;

        &:not(:last-child) {
          margin-right: 24px;
        }

        a {
          height: 100%;
          color: $primary-text-color;
          text-decoration: none;
          font-weight: 600;
          @extend .d-flex;
          @extend .align-items-center;
          @include transition($transition-fast);

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}