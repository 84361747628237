.DayPicker__withBorder {
  box-shadow: none;
}

.DayPickerNavigation_button__default {
  // min-width: 36px;
  // padding-left: 7px;
  // padding-right: 7px;
  @extend .btn;
  @extend .btn-text;
  @extend .btn-icon;
  @extend .btn-only-icon;
}

.DayPickerNavigation_svg__horizontal {
  fill: $secondary;
}

.CalendarMonth_caption {
  padding-top: 26px;
  @extend .text-gray-900;
  @extend .text-md;

  strong {
    @extend .fw-semibold;
  }
}

// NOTE: the order of these styles DO matter
.CalendarDay {
  border: none;

  &:hover {
    border: none;
  }
}

.CalendarDay__default:not(.CalendarDay__selected_span):not(.CalendarDay__selected) {
  // border-radius: 20px;
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: $carnation; //background
  color: $white; //text
  // border: 1px solid red; //default styles include a border
}

.CalendarDay__selected_span:hover {
  background: $valencia;
  color: $white;
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $carnation;
  color: $white;

  &_start {
    // border-radius: 20px 0 0 20px;
  }

  &_end {
    // border-radius: 0 20px 20px 0;
  }
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: $valencia;
  color: $white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $carnation;
  color: $white;
  border-radius: 0 !important;
}