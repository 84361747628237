.bg-gradient-white {
  background-image: linear-gradient(to bottom, transparent, white)
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 36px;
  min-width: 36px;
  height: 36px;
  margin-left: -36px;
}
.lds-ring .spinner {
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid $astral;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $astral transparent transparent transparent;
  position: absolute;
  top: 8px;
  left: 8px;
}
.lds-ring .spinner:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring .spinner:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring .spinner:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.coupon-success {
  margin: -8px 0 0 -30px;
  height: 36px;
}