.table-container {
  border: 1px solid $border-color;
  border-radius: 4px;
  // overflow: hidden;
}

.table {
  table-layout: fixed;
  margin-bottom: 0;

  th, td {
    height: 52px;
    padding: 0 16px;
    font-size: $text-md-fz;
    line-height: $text-md-lh;
    word-wrap: break-word;
    vertical-align: middle;

    &:last-child:not(:first-child) {
      text-align: right;
    }
  }

  thead {
    th {
      border-top: none;
      border-bottom: none;
      background-color: $gray-200;
      font-weight: 600;
      vertical-align: middle;

      &:first-child {
        border-top-left-radius: 3px;
      }

      &:last-child {
        border-top-right-radius: 3px;
      }
    }
  }

  // States
  &-hover {
    tbody tr:hover {
      cursor: pointer;
      background-color: $gray-100;
    }
  }
}
