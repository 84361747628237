.t {
  &--6 {
    top: -$spacer * 6;
  }
  &-0 {
    top: 0
  }
  &-1 {
    top: $spacer
  }
  &-2 {
    top: $spacer * 2
  }
  &-3 {
    top: $spacer * 3
  }
  &-4 {
    top: $spacer * 4
  }
  &-5 {
    top: $spacer * 5
  }
  &-6 {
    top: $spacer * 6
  }
  &-7 {
    top: $spacer * 7
  }
  &-8 {
    top: $spacer * 8
  }
  &-9 {
    top: $spacer * 9
  }
  &-10 {
    top: $spacer * 10
  }
  &-11 {
    top: $spacer * 11
  }
  &-12 {
    top: $spacer * 12
  }
  &-13 {
    top: $spacer * 13
  }
  &-14 {
    top: $spacer * 14
  }
  &-15 {
    top: $spacer * 15
  }
  &-16 {
    top: $spacer * 16
  }
  &-17 {
    top: $spacer * 17
  }
  &-18 {
    top: $spacer * 18
  }
  &-19 {
    top: $spacer * 19
  }
  &-20 {
    top: $spacer * 20
  }

  // Responsive
  &-sm{
    &-6 {
      @include media-breakpoint-up(sm) {
        top: $spacer * 6
      }
    }
  }
  &-md{}
  &-lg{
    &-22 {
      @include media-breakpoint-up(lg) {
        top: $spacer * 22
      }
    }
  }
  &-xl{}
  &-xxl{}
}

.l {
  &-0 {
    left: 0
  }
  &-1 {
    left: $spacer
  }
  &-2 {
    left: $spacer * 2
  }
  &-3 {
    left: $spacer * 3
  }
  &-4 {
    left: $spacer * 4
  }
  &-5 {
    left: $spacer * 5
  }
  &-6 {
    left: $spacer * 6
  }
  &-7 {
    left: $spacer * 7
  }
  &-8 {
    left: $spacer * 8
  }

  // Responsive
  &-sm{}
  &-md{}
  &-lg{}
  &-xl{}
  &-xxl{}
}

.b {
  &-0 {
    bottom: 0
  }
  &-1 {
    bottom: $spacer
  }
  &-2 {
    bottom: $spacer * 2
  }
  &-3 {
    bottom: $spacer * 3
  }
  &-4 {
    bottom: $spacer * 4
  }
  &-5 {
    bottom: $spacer * 5
  }
  &-6 {
    bottom: $spacer * 6
  }
  &-7 {
    bottom: $spacer * 7
  }
  &-8 {
    bottom: $spacer * 8
  }
  &-12 {
    bottom: $spacer * 12
  }

  // Responsive
  &-sm{}
  &-md{}
  &-lg{}
  &-xl{}
  &-xxl{}
}

.r {
  &-0 {
    right: 0
  }
  &-1 {
    right: $spacer
  }
  &-2 {
    right: $spacer * 2
  }
  &-3 {
    right: $spacer * 3
  }
  &-4 {
    right: $spacer * 4
  }
  &-5 {
    right: $spacer * 5
  }
  &-6 {
    right: $spacer * 6
  }
  &-7 {
    right: $spacer * 7
  }
  &-8 {
    right: $spacer * 8
  }

  // Responsive
  &-sm{}
  &-md{
    &-5 {
      @include media-breakpoint-up(md) {
        right: $spacer * 5
      }
    }
    &-10 {
      @include media-breakpoint-up(md) {
        right: $spacer * 10
      }
    }
  }
  &-lg{
    &-6 {
      @include media-breakpoint-up(lg) {
        right: $spacer * 6
      }
    }
  }
  &-xl{}
  &-xxl{
    &-60 {
      @include media-breakpoint-up(xxl) {
        right: $spacer * 60
      }
    }
  }
}

.z-index {
  &-1 {
    z-index: 1;
  }
  &-2 {
    z-index: 2;
  }
  &-3 {
    z-index: 3;
  }
}

.position {
  &-centered {
    top: 50%;
    left: 50%;
    @include translate (-50%, -50%);
  }
  &-x-centered {
    left: 50%;
    @include translate (-50%, 0);
  }
  &-y-centered {
    top: 50%;
    @include translate (0, -50%);
  }

  &-md {
    &-relative {
      @include media-breakpoint-up(md) {
        position: relative !important;
      }
    }
    &-absolute {
      @include media-breakpoint-up(md) {
        position: absolute !important;
      }
    }
    &-sticky {
      @include media-breakpoint-up(md) {
        position: sticky !important;
      }
    }
    &-static {
      @include media-breakpoint-up(md) {
        position: static !important;
      }
    }

    &-y-none,
    &-x-none {
      @include media-breakpoint-up(md) {
        top: unset;
        left: unset;
        @include translate (0, 0);
      }
    }
  }
  &-lg {
    &-relative {
      @include media-breakpoint-up(lg) {
        position: relative !important;
      }
    }
    &-absolute {
      @include media-breakpoint-up(lg) {
        position: absolute !important;
      }
    }
    &-sticky {
      @include media-breakpoint-up(lg) {
        position: sticky !important;
      }
    }
    &-static {
      @include media-breakpoint-up(lg) {
        position: static !important;
      }
    }

    &-y-none,
    &-x-none {
      @include media-breakpoint-up(lg) {
        top: unset;
        left: unset;
        @include translate (0, 0);
      }
    }
  }
}