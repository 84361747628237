.avatar {
  width: $avatar-md-h;
  min-width: $avatar-md-h;
  height: $avatar-md-h;
  border: 1px solid $avatar-border-color;
  border-radius: $avatar-border-radius;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @extend .object-fit-cover;
    @extend .object-position-center;
  }

  .placeholder {
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    max-width: unset;
    @include calc(width, '100% + 2px');
    @include calc(height, '100% + 2px');
  }

  .text {
    color: $avatar-color;
    font-weight: $avatar-fw;
    font-size: $avatar-md-fz;
    line-height: $avatar-md-lh;
    text-transform: uppercase;
    display: block;
    width: 100%;
    height: 100%;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
  }

  // Sizes
  &-sm {
    width: $avatar-sm-h;
    min-width: $avatar-sm-h;
    height: $avatar-sm-h;

    .text {
      font-size: $avatar-sm-fz;
      line-height: $avatar-sm-lh;
    }
  }

  &-lg {
    width: $avatar-lg-h;
    min-width: $avatar-lg-h;
    height: $avatar-lg-h;

    .text {
      font-size: $avatar-lg-fz;
      line-height: $avatar-lg-lh;
    }
  }

  &-xl {
    width: $avatar-xl-h;
    min-width: $avatar-xl-h;
    height: $avatar-xl-h;

    .text {
      font-size: $avatar-xl-fz;
      line-height: $avatar-xl-lh;
    }
  }
}