.pill {
  padding: 4px 8px;
  color: $gray-700;
  background-color: $gray-200;
  border: 1px solid $gray-700;
  border-radius: 24px;
  @extend .text-xs;
  @extend .d-inline-flex;

  // Types
  &-success {
    color: $success;
    background-color: $loafer;
    border-color: $success;
  }

  &-danger {
    color: $danger;
    background-color: $wisp-pink;
    border-color: $danger;
  }

  &-warning {
    color: $warning;
    background-color: $half-colonial-white;
    border-color: $warning;
  }

  &-info {
    color: $info;
    background-color: $hawkes-blue;
    border-color: $info;
  }
}