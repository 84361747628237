// @[Definitions]
// Base
$primary-font:                       $base-font;
$primary-font-size:                  $text-md-fz;
$primary-line-height:                $text-md-lh;
$primary-text-color:                 $gray-900;
$primary-bg-color:                   $white;
$primary-font-weight:                400;

// Theme colors
$primary:                            $carnation;
$secondary:                          $gray-800;
$success:                            $celery;
$info:                               $jordy-blue;
$warning:                            $fuel-yellow;
$danger:                             $mandy;
$disabled:                           $gray-600;

// Button
$btn-h:                              36px;
$btn-fz:                             14px;
$btn-lh:                             20px;
$btn-p:                              7px 15px;

$btn-sm-w:                           24px;
$btn-sm-h:                           24px;
$btn-sm-fz:                          14px;
$btn-sm-lh:                          20px;
$btn-sm-p:                           3px 3px;

$btn-radius:                         4px;
$btn-fw:                             600;

$btn-primary-bg:                     $primary;
$btn-primary-border:                 $primary;
$btn-primary-color:                  $white;
$btn-primary-hover-bg:               $cinnabar;
$btn-primary-hover-border:           $cinnabar;
$btn-primary-active-bg:              $valencia;
$btn-primary-active-border:          $valencia;
$btn-primary-shadow:                 0 0 0 2px $apricot-peach; // Available for focus & active states only

$btn-primary-light-bg:               $apricot-peach;
$btn-primary-light-border:           $apricot-peach;
$btn-primary-light-color:            $carnation;
$btn-primary-light-hover-bg:         $tacao;
$btn-primary-light-hover-border:     $tacao;
$btn-primary-light-active-bg:        $burnt-sienna;
$btn-primary-light-active-border:    $burnt-sienna;
$btn-primary-light-shadow:           0 0 0 2px $apricot-peach; // Available for focus & active states only

$btn-primary-disabled-bg:            $disabled;
$btn-primary-disabled-border:        $disabled;
$btn-primary-disabled-color:         $white;

$btn-secondary-bg:                   transparent;
$btn-secondary-border:               $gray-600;
$btn-secondary-color:                $gray-800;
$btn-secondary-hover-bg:             $linen;
$btn-secondary-hover-border:         $gray-600;
$btn-secondary-hover-color:          $cinnabar;
$btn-secondary-active-bg:            $apricot-peach;
$btn-secondary-active-border:        $gray-600;
$btn-secondary-active-color:         $cinnabar;
$btn-secondary-shadow:               0 0 0 2px $apricot-peach; // Available for focus & active states only

$btn-secondary-light-bg:             transparent;
$btn-secondary-light-border:         $carnation;
$btn-secondary-light-color:          $carnation;
$btn-secondary-light-hover-bg:       $linen;
$btn-secondary-light-hover-border:   $cinnabar;
$btn-secondary-light-hover-color:    $cinnabar;
$btn-secondary-light-active-bg:      $apricot-peach;
$btn-secondary-light-active-border:  $cinnabar;
$btn-secondary-light-active-color:   $cinnabar;
$btn-secondary-light-shadow:         0 0 0 2px $apricot-peach; // Available for focus & active states only

$btn-secondary-disabled-bg:          transparent;
$btn-secondary-disabled-border:      $disabled;
$btn-secondary-disabled-color:       $disabled;

$btn-text-bg:                        transparent;
$btn-text-border:                    transparent;
$btn-text-color:                     $gray-800;
$btn-text-hover-bg:                  $linen;
$btn-text-hover-border:              transparent;
$btn-text-hover-color:               $cinnabar;
$btn-text-active-bg:                 $apricot-peach;
$btn-text-active-border:             transparent;
$btn-text-active-color:              $cinnabar;
$btn-text-shadow:                    0 0 0 2px $apricot-peach; // Available for focus & active states only

$btn-text-light-bg:                  transparent;
$btn-text-light-border:              transparent;
$btn-text-light-color:               $carnation;
$btn-text-light-hover-bg:            $linen;
$btn-text-light-hover-border:        transparent;
$btn-text-light-hover-color:         $cinnabar;
$btn-text-light-active-bg:           $apricot-peach;
$btn-text-light-active-border:       transparent;
$btn-text-light-active-color:        $cinnabar;
$btn-text-light-shadow:              0 0 0 2px $apricot-peach; // Available for focus & active states only

$btn-text-disabled-bg:               transparent;
$btn-text-disabled-border:           transparent;
$btn-text-disabled-color:            $disabled;

$btn-social-bg:                      transparent;
$btn-social-border:                  $gray-900;
$btn-social-color:                   $gray-900;
$btn-social-hover-bg:                $gray-100;
$btn-social-hover-border:            $gray-900;
$btn-social-hover-color:             $astral;
$btn-social-active-bg:               $gray-200;
$btn-social-active-border:           $casal;
$btn-social-active-color:            $gray-900;
$btn-social-shadow:                  0 0 0 2px $aqua-haze; // Available for focus & active states only

// Hyperlink
$hyperlink-color:                    $gray-900;
$hyperlink-fw:                       600;

$hyperlink-hover-color:              $cinnabar;
$hyperlink-focus-color:              $gray-900;
$hyperlink-active-color:             $valencia;
$hyperlink-shadow:                   0 0 0 2px $apricot-peach;

$hyperlink-light-color:              $carnation;
$hyperlink-light-focus-color:        $carnation;

$hyperlink-disabled-color:           $disabled;

// Toggle
$toggle-border-color:                $primary;
$toggle-padding:                     1px;
$toggle-border-radius:               5px;

// Tabs
$tab-fz:                             $text-md-fz;
$tab-lh:                             $text-md-lh;
$tab-fw:                             600;

$tab-sm-p:                           10px 12px 7px;
$tab-md-p:                           14px 12px 11px;
$tab-lg-p:                           20px 12px 17px;
$tab-ver-p:                          8px 10px;

$tab-color:                          $gray-800;
$tab-bg:                             $white;
$tab-border:                         transparent;
$tab-hover-color:                    $gray-900;
$tab-hover-bg:                       $gray-100;
$tab-hover-border:                   transparent;
$tab-active-color:                   $gray-900;
$tab-active-bg:                      $gray-200;
$tab-active-border:                  transparent;
$tab-selected-color:                 $astral;
$tab-selected-bg:                    $white;
$tab-selected-vertical-bg:           $aqua-haze;
$tab-selected-border:                $astral;

// @[Form Elements]
// Input
$input-h:                            36px;
$input-radius:                       4px;
$input-color:                        $gray-900;
$input-border-color:                 $gray-600;
$input-placeholder-color:            $gray-600;
$input-fz:                           $text-md-fz;
$input-lh:                           $text-md-lh;
$input-fw:                           400;
$input-bg:                           $white;

$input-hover-border:                 $gray-900;

$input-focus-border:                 $carnation;
$input-focus-shadow:                 0 0 0 2px $linen;

$input-valid-focus-border:           $celery;
$input-valid-focus-shadow:           0 0 0 2px $loafer;
$input-invalid-focus-border:         $mandy;
$input-invalid-focus-shadow:         0 0 0 2px $wisp-pink;

$input-disabled-color:               $disabled;
$input-disabled-placeholder-color:   $disabled;
$input-disabled-border:              $disabled;

// Switch
$switch-color:                       $gray-700;
$switch-hover-color:                 $gray-800;
$switch-active-color:                $gray-800;
$switch-shadow:                      0 0 0 2px $gray-300; // Available for focus & active states only

$switch-checked-color:               $astral;
$switch-checked-hover-color:         $paradiso;
$switch-checked-active-color:        $paradiso;
$switch-checked-shadow:              0 0 0 2px $aqua-haze; // Available for focus & active states only
$switch-checked-bg:                  $astral;

$switch-disabled-color:              $disabled;

// Checkbox
$checkbox-bg:                        transparent;
$checkbox-border:                    $gray-700;

$checkbox-hover-bg:                  $gray-200;
$checkbox-hover-border:              $gray-700;

$checkbox-active-bg:                 $gray-200;
$checkbox-active-border:             $gray-700;
$checkbox-shadow:                    0 0 0 2px $gray-300; // Available for focus & active states only

$checkbox-checked-bg:                $astral;
$checkbox-checked-border:            $astral;
$checkbox-checked-hover-bg:          $paradiso;
$checkbox-checked-hover-border:      $paradiso;
$checkbox-checked-active-bg:         $paradiso;
$checkbox-checked-active-border:     $paradiso;
$checkbox-checked-shadow:            0 0 0 2px $aqua-haze; // Available for focus & active states only

$checkbox-disabled-bg:               transparent;
$checkbox-disabled-border:           $disabled;

$checkbox-checked-disabled-bg:       $disabled;
$checkbox-checked-disabled-border:   $disabled;

// Range
$range-input-width:                  128px;
$range-input-height:                 4px;
$range-input-radius:                 20px;
$thumb-size:                         16px;
$thumb-radius:                       100%;

// Alert
$alert-p:                            10px 0;
$alert-sm-p:                         8px 8px;
$alert-radius:                       4px;
$alert-fz:                           $text-md-fz;
$alert-lh:                           $text-md-lh;

$alert-info-color:                   $casal;
$alert-info-bg:                      $hawkes-blue;

$alert-danger-color:                 $carnation;
$alert-danger-bg:                    $linen;

// Avatar
$avatar-border-color:                $gray-500;
$avatar-border-radius:               50%;
$avatar-color:                       $white;
$avatar-fw:                          700;

$avatar-xl-w:                        118px;
$avatar-xl-h:                        118px;
$avatar-xl-fz:                       $h1-fz;
$avatar-xl-lh:                       $h1-lh;

$avatar-lg-w:                        80px;
$avatar-lg-h:                        80px;
$avatar-lg-fz:                       $h2-fz;
$avatar-lg-lh:                       $h2-lh;

$avatar-md-w:                        48px;
$avatar-md-h:                        48px;
$avatar-md-fz:                       $h3-fz;
$avatar-md-lh:                       $h3-lh;

$avatar-sm-w:                        36px;
$avatar-sm-h:                        36px;
$avatar-sm-fz:                       $text-xs-fz;
$avatar-sm-lh:                       $text-xs-lh;

// Chips
$chips-h:                            28px;

$chips-color:                        $secondary;
$chips-bg:                           $gray-200;
$chips-hover-bg:                     $gray-300;
$chips-focus-border:                 $apricot-peach;
$chips-active-bg:                    $gray-400;
$chips-selected-color:               $carnation;
$chips-selected-bg:                  $apricot-peach;
$chips-disabled-bg:                  $gray-200;

// Snacks
$snack-p:                            8px 12px;
$snack-fz:                           $text-xs-fz;
$snack-lh:                           $text-xs-lh;

$snack-success-bg:                   $loafer;
$snack-info-bg:                      $hawkes-blue;
$snack-warning-bg:                   $half-colonial-white;
$snack-danger-bg:                    $wisp-pink;

// Header
$header-height:                      60px;
$header-bg:                          $white;

// Footer
$footer-height:                      48px;
$footer-bg:                          $white;

// Modals
$modal-sm-width:                     284px;
$modal-md-width:                     480px;
$modal-lg-width:                     640px;
$modal-xl-width:                     864px;
$modal-xl-width-lg:                  1100px;
$modal-xl-height:                    '100vh - 2 * 72px';
$modal-xl-body-height:               '100vh - 2 * 72px - 68px - 84px';
// Banners
$banner-height:                      48px;

// Transitions
$base-timing-function:               ease-in-out;

$transition-fast:                    150ms $base-timing-function;
$transition-normal:                  300ms $base-timing-function;
$transition-slow:                    450ms $base-timing-function;

$transition-dialog:                  $transition-fast;
$transition-details-bar:             250ms ease;
